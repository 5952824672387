<template>
    <button :disabled="loading" class="w-full sm:w-2/5 flex items-center justify-center bg-red-500 hover:bg-black text-white text-sm font-semibold leading-snug tracking-wide uppercase shadow cursor-pointer px-8 py-3 transition-all duration-300 ease-in">
        <div v-if="loading" class="btn-spinner mr-2" />
        <slot />
    </button>
</template>

<script>
    export default {
        props: {
            loading: Boolean,
        },
    }
</script>
