window.axios = require('axios')
window.Vue = require('vue')
import Vuelidate from 'vuelidate'
window.Flickity = require('flickity')

import barba from '@barba/core'
import { gsap } from "gsap"
import LocomotiveScroll from 'locomotive-scroll'

Vue.config.productionTip = false
Vue.use(Vuelidate)
let app = document.getElementById('app')

import contactform from "./components/ContactForm";

new Vue({
    el: '#app',
    components: { contactform}
})

// require('./modules/headroom')
require('./modules/navigation')


function pageExitTransition(data) {
    var tl = gsap.timeline()
    tl.to(data.current.container, {opacity: 0, duration: 0.5, ease: "sine.out"})
}

function pageEnterTransition(data) {
    var tl = gsap.timeline()
    tl.from(data.next.container, {opacity: 0, duration: 0.5, ease: "sine.out"})
}

function heroTitleAnimation() {
    const titleArr = Array.from(document.querySelectorAll('.hero-title__line'));

    var tl = gsap.timeline();
    tl.to('.hero-title__line div', { duration: 0.8, y: '0%', ease: "power4.outout", stagger: .4})
    tl.to('.hero-title__line strong span', { transform: 'scaleX(1)' })
}

heroTitleAnimation()

function delay(n) {
    n = n || 2000
    return new Promise(done => {
        setTimeout(() => {
            done()
        },n)
    })
}

// let scroll;

// barba.init({
//     debug: true,
//     sync: true,
//     views: [{
//         namespace: 'home',
//         enter({ next}) {
//             console.log('hello')
//         }
//     }],
//     transitions: [
//         {
//             name: 'page-transition',
//             async once({ next }) {
//                 smooth(next.container)
//                 heroTitleAnimation()
//             },
//             async leave(data) {
//                 const done = this.async()
//                 pageExitTransition(data)
//                 await delay(1500)
//                 done()
//             },
//             afterLeave({ current, next, trigger }) {
//                 scroll.destroy();
//             },
//             async enter(data) {
//                 smooth(data.next.container)
//                 pageEnterTransition(data)
//                 heroTitleAnimation()
//
//             },
//             after({ current, next, trigger }) {
//                 scroll.update()
//             },
//         },
//     ],
// })

// function smooth(container) {
//     scroll = new LocomotiveScroll({
//         el: container.querySelector('[data-scroll-container]'),
//         smooth: true,
//         getSpeed: true,
//         getDirection: true,
//     })
// }

    if(document.querySelector('[data-scroll-container]')) {
        const scroll = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true,
            getSpeed: true,
            getDirection: true,
        })
    }


