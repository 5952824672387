<template>
    <form  method="post" @submit.prevent="validate">
        <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-1/2 px-3">
                <label class="block text-gray-800 text-md font-semibold" for="contact-name">
                    <input
                        type="text"
                        id="contact-name"
                        placeholder="nume"
                        name="contact-name"
                        class="block w-full border border-gray-500 outline-none px-5 py-3"
                        v-model.trim="$v.form.name.$model"
                        @blur="$v.form.name.$touch()"
                    >
                </label>
                <span class="block text-xs text-red-500 mt-1"
                  v-if="$v.form.name.$error && !$v.form.name.required">
                        Campul Nume este obligatoriu
                </span>
            </div>
            <div class="mt-5 lg:mt-0 px-3 w-full lg:w-1/2">
                <label class="block text-black text-md font-semibold" for="contact-email">
                    <input
                        type="email"
                        id="contact-email"
                        placeholder="email@domain.com"
                        name="contact-email"
                        class="block w-full border border-gray-500 outline-none px-5 py-3"
                        v-model.trim="$v.form.email.$model"
                        @blur="$v.form.email.$touch()"
                    >
                </label>
                <span class="text-xs text-red-500 mt-1"
                      v-if="$v.form.email.$error && !$v.form.email.required">
                        Campul Email este obligatoriu
                </span>
                <span class="block text-xs text-red-500 mt-1"
                      v-if="$v.form.email.$error && !$v.form.email.email">
                        Va rugam sa introduceti o adresa de email valida
                </span>
            </div>
        </div>
        <div class="flex flex-wrap mt-5 -mx-3">
            <div class="px-3 w-full lg:w-1/2 ">
                <label class="block text-gray-800 text-sm font-semibold" for="contact-phone">
                    <input
                        type="text"
                        id="contact-phone"
                        placeholder="telefon"
                        name="contact-phone"
                        class="block w-full border border-gray-500 outline-none px-5 py-3"
                        v-model.trim="$v.form.phone.$model"
                        @blur="$v.form.phone.$touch()"
                    >
                </label>
                <span class="text-xs text-red-500 mt-1"
                      v-if="$v.form.phone.$error && !$v.form.phone.required">
                        Campul Telefon este obligatoriu
                </span>
            </div>
            <div class="mt-5 lg:mt-0 px-3 w-full lg:w-1/2 ">
                <label class="block text-black text-sm font-semibold" for="contact-company">
                    <input
                        type="text"
                        id="contact-company"
                        placeholder="companie"
                        name="company"
                        class="block w-full border border-gray-500 outline-none px-4 py-3"
                        v-model.trim="$v.form.company.$model"
                        @blur="$v.form.company.$touch()"

                    />
                </label>
                <span class="text-xs text-red-500 mt-1"
                      v-if="$v.form.company.$error && !$v.form.company.required">
                        Campul Companie este obligatoriu
                </span>
            </div>
        </div>
        <div class="w-full mt-6">
            <label class="block text-gray-800 text-sm" for="contact-message">
                <textarea
                        id="contact-message"
                        rows="6"
                        name="message"
                        class="block w-full border border-gray-500 outline-none appearance-none px-5 py-3"
                        placeholder="mesaj"
                        v-model.trim="$v.form.message.$model"
                        @blur="$v.form.message.$touch()"
                ></textarea>
                <span class="text-xs text-red-500 mt-1"
                      v-if="$v.form.message.$error && !$v.form.message.required">
                        Campul Mesaj este obligatoriu
                </span>
            </label>
        </div>
        <div class="w-full mt-3 lg:mt-5 md:-mx-3">
            <div class="flex items-center px-3">
                <label class="block text-gray-800 text-sm font-semibold" for="terms">
                    <input
                            type="checkbox"
                            class="text-gray-500"
                            id="terms"
                            name="terms"
                            v-model="form.terms_accepted"
                            value="true"
                    >
                </label>
                <div class="text-gray-500 ml-3" >
                    Am luat la cunostinta
                    <a href="/termeni-si-conditii" class="underline text-gray-500 hover:text-gray-800" title="Cisteste termenii si conditiile de utilizare a site-ului"> termenii si condiii</a>
                </div>
            </div>
            <span class="block text-xs text-red-500 mt-1 px-3"
              v-if="$v.form.terms_accepted.$error && !$v.form.terms_accepted.required">
                    Te rugam sa confirmi ca ai luat la cunostinta termenii si conditiile de utilizare ale site-ului.
                </span>
            <div class="flex justify-start w-full mt-8 px-3">
                <loading-button :loading="isSubmitting" type="submit">trimite</loading-button>
            </div>
        </div>
    </form>
</template>

<script>
    import LoadingButton from '../components/LoadingButton'
    import { required, email } from 'vuelidate/lib/validators'
    export default {
        components: { LoadingButton },
        data() {
            return {
                form : {
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    message: '',
                    terms: false,
                },
                errors: {},
                isSubmitting: false,
                isError: false,
                errorMessage: null
            }
        },
        validations: {
            form : {
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                phone: {
                    required
                },
                company : {
                    required
                },
                message : {
                    required
                },
                terms_accepted: {
                    checked: value => value === true
                }
            }
        },
        methods: {
            validate() {
                this.$v.form.$touch()

                if(!this.$v.form.$invalid) {
                    this.onSubmit()
                } else {
                    this.$v.form.$touch()
                }
            },
            onSubmit() {
                this.isSubmitting = true
                const headers = { 'Access-Control-Allow-Origin:': '*' }

                axios
                    .post('/formular-contact.php', this.form, headers)
                    .then(this.onSubmitSuccess)
                    .catch(this.onSubmitFailure)
                    .then(this.onSubmitAlways, this.onSubmitAlways);
            },

            onSubmitSuccess(response) {
                console.log(response)
                const status = response.data.message;

                if (status === 'message sent') {
                    this.$v.form.$reset()
                    this.form = {}
                    window.location = 'https://www.heat.ro/contact-raspuns/'
                } else {
                    this.isError = false;
                    this.form = {};
                }
            },
            onSubmitFailure(error) {
                console.log(error.response.data)
                this.$data.isError = true;

                this.$data.errorMessage = error.response.data.message;
                this.$data.errors = error.response.data.errors || {};

            },
            onSubmitAlways() {
                this.$data.isSubmitting = false;
            },
        }
    }
</script>
